import { SearchSchemaInput, createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';
import { queries } from '../../../../../utils/constants/queryKeys';

const searchSchema = z.object({
  hideTotal: z.boolean().catch(false),
  hideReferences: z.boolean().catch(false),
  hidePrices: z.boolean().catch(false),
  hideAddresses: z.boolean().catch(true),
});

export const Route = createFileRoute('/app/businesses-rma/business/$businessId/quotation')({
  validateSearch: (data: { hideTotal?: boolean; hideReferences?: boolean; hidePrices?: boolean; hideAddresses?: boolean } & SearchSchemaInput) =>
    searchSchema.parse(data),
  loader: async ({ context: { queryClient }, params: { businessId } }) => {
    await queryClient.ensureQueryData(queries['business-quotations'].detail._ctx.byBusinessId(businessId));
  },
});
